import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Outlet, useLocation } from "react-router";
import ChatwootWidget from "~/components/ChatwootWidget";
import Header from "~/components/Header";
import MetaTags from "~/components/MetaTags";
import MissingInformationModal from "~/components/MissingInformationModal";
import OgImage from "~/components/OgImage";
import useAuthentication from "~/hooks/useAuthentication";

export default function RootLayout() {
  const { signOut } = useAuthentication();
  const { state } = useLocation();

  useEffect(() => {
    state?.signOut && signOut(true);
  }, [signOut, state]);

  return (
    <>
      <Helmet>
        <link href="/apple-touch-icon.png" rel="apple-touch-icon" sizes="180x180" />
        <link href="/site.webmanifest" rel="manifest" />
        <link color="#3182CE" href="/maskable.png" rel="mask-icon" />
        <link href="/blog/feed.xml" rel="alternate" title="WebGazer Blog feed" type="application/atom+xml" />
        <meta content="#117BBF" name="theme-color" />
      </Helmet>

      <ChatwootWidget />

      <MissingInformationModal />

      <MetaTags />

      <OgImage image={{ height: 1260, url: "/og-image.png", width: 2400 }} />

      <div className="d-flex flex-column flex-grow-1">
        <Header />

        <Outlet />
      </div>
    </>
  );
}
