import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import AuthenticationContext, { AuthenticationContextValue } from "~/contexts/AuthenticationContext";
import { setUserAccessToken } from "~/helpers/userAccessToken";
import useAuthenticationUser from "~/hooks/api/useAuthenticationUser";

export type AuthenticationProviderProps = {
  children: ReactNode;
};

type State = Pick<AuthenticationContextValue, "isNavigationInProgress">;

export default function AuthenticationProvider({ children }: AuthenticationProviderProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState<State>({ isNavigationInProgress: false });
  const { data: user, isValidating, mutate } = useAuthenticationUser();
  const canWrite = useMemo<AuthenticationContextValue["canWrite"]>(() => ["ADMIN", "OWNER"].includes(user?.role || ""), [user]);
  const isOrganizationOwner = useMemo<AuthenticationContextValue["isOrganizationOwner"]>(() => user?.role === "OWNER", [user]);

  const refresh = useCallback(async () => {await mutate();}, [mutate]);

  const signOut = useCallback(async (forReal?: boolean) => {
    if (forReal) {
      setUserAccessToken(null);
      await mutate(null);
    } else {
      navigate("/", { state: { signOut: true } });
    }
  }, [mutate, navigate]);

  const setIsNavigationInProgress = useCallback((isNavigationInProgress: boolean) => setState((s) => ({
    ...s,
    isNavigationInProgress,
  })), []);

  useEffect(() => {
    setIsNavigationInProgress(false);
  }, [location, setIsNavigationInProgress]);

  return (
    <AuthenticationContext.Provider
      value={{
        canWrite,
        isNavigationInProgress: state.isNavigationInProgress,
        isOrganizationOwner,
        isValidating,
        refresh,
        setIsNavigationInProgress,
        signOut,
        user,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}
